#recruit-page {
  .section {
    h3 {
      padding-bottom: rem(10px);
    }
    .greeting-text {
      margin-bottom: rem(80px);
      font-size: rem(20px);
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
    &.peer .container, &.employ .container {
      //height: 60vh;
    }
    @include media-breakpoint-down(md) {
      .image-description {
        img {
          width: 100%;
        }
        .desc-text {
          font-size: rem(12px);
        }
      }
      /*.description-wrapper {
         > div.col-lg-3  {
          padding-top: rem(50px);
        }
      }*/
    }
  }
}