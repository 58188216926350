#service-page {
  a {
    text-decoration: none;
  }
  .section {
    .btn-box {
      border: 1px solid rgb(48, 83, 144); //#305390
      background-color: rgb(68, 114, 196); //#4472c4
      color: $white;
      width: 40%;
      @include rem(font-size, 18px);
      @include rem(padding, 6px 10px);
      margin-top: rem(20px);
      @include media-breakpoint-down(md) {
        margin-top: rem(10px);
      }
    }
    //로어드민 서비스
    &.service {
      .row {
        height: 60vh;
        @include media-breakpoint-down(md) {
          height: unset;
        }
      }

      .first-letter {
        @include rem(font-size, 20px);
      }
      .left-wrapper {
        img {
          width: 80%;
          @include media-breakpoint-down(md) {
            width: 45%;
            margin: 0 auto;
          }
        }
      }
      .right-wrapper {
        @include media-breakpoint-down(md) {
          p {
            margin-bottom: rem(6px);
          }
        }
        .list-group {
          @include rem(padding-left, 30px);
          @include media-breakpoint-down(md) {
            margin-bottom: rem(10px);
          }

          li {
            @include rem(margin-bottom, 16px);
            @include media-breakpoint-down(md) {
              @include rem(margin-bottom, 0px);
            }
          }
        }
      }
    }
    &.future {
      .container {
        height: 60vh;
        @include media-breakpoint-down(md) {
          height: unset;
        }
      }
      .row {
        > div.col-lg-4 {
          @include media-breakpoint-down(md) {
            @include rem(margin-top, 10px);
          }
        }
      }
      .image-description {
        > div {
          &:last-child {
            min-height: rem(0px);
          }
        }
      }
    }
    .shortcuts {
      margin: 0 auto;
      top: rem(-100px);
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }
}