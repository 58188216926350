//FullPage 오른편 네비게이션중 마지막 섹션(footer) 버튼은 나오지 않도록 숨김 처리
#fp-nav {
  > ul > li {
    &:last-child {
      display: none;
    }
  }
  &.fp-right {
    right: rem(-5px);
  }
}

footer {
  color: $footer-color;
  background-color: $footer-backgroud-color;
  @include rem(padding-top, 15px);
  @include rem(padding-bottom, 15px);
  @include rem(font-size, 14px);
  font-family: 'Noto Sans KR', sans-serif;

  address {
    @include rem(font-size, 12px)
  }

  a {
    color: $footer-color;
    text-decoration: none;
    @include hover-focus-active {
      color: $white;
      text-decoration: none;
    }
  }
}