.section {
  @include media-breakpoint-down(md) {
    .container.h-75 {
      //height: unset !important;
    }
  }
  &.container-fluid {
    > h3 {
      padding: {
        top: rem(100px);
        left: rem(90px);
      };
      @include media-breakpoint-down(md) {
        padding: {
          top: rem(100px);
          left: rem(0px);
        };
        font-size: rem(20px);
      }
    }
  }
  .image-description {
    > div {
      &:first-child {
        text-align: center;
        margin-bottom: rem(16px);
      }
      &:last-child {
        min-height: rem(160px);
      }
    }
    img {
      width: 60%;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
    .desc-text {
      font-size: rem(14px);
    }
  }

}