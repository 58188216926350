#index-page {
  ol{
    &.list-group {
      padding-top: rem(16px);
      li {
        margin-bottom: rem(16px);
      }
    }
  }
  @include media-breakpoint-down(md) {
    ol {
      &.list-group {
        display: none;
      }
    }
  }
  .section {
    //솔업의 비전과 미션 섹션
    &.vision {
      $visual-font-size: 40px;
      h4 {
        color: $white;
        font-weight: bold;
        padding-top: rem(18px), 0, rem(40px), 0;
        @include media-breakpoint-down(md) {
          padding-top: rem(0px), 0, rem(0px), 0;
        }
      }
      .red {
        color: $site-red-color;
      }
      .blue {
        color: $site-blue-color;
      }
      .left-wrapper {
        padding-right: rem(50px);

        @include media-breakpoint-up(lg) {
          .align-line {
            position: absolute;
            right: 0;
            height: 100%;
            top: 0;
            border-right: 2px solid rgb(76, 76, 177); //#4c4cb1
          }
        }
        .first-words {
          @include rem(font-size, $visual-font-size);
          color: $white;
          font-weight: bold;
          line-height: rem(45px);
        }
        .second-words {
          font-size: rem($visual-font-size);
          text-align: right;
          font-weight: bold;
          line-height: rem(40px);
        }
        ol{
          &.list-group {
            li {
              font-size: rem(14px);
            }
          }
        }
        @include media-breakpoint-down(md) {
          padding-right: rem(0px);
          border-right: 0px solid rgb(76, 76, 177); //#4c4cb1
          .first-words, .second-words {
            font-size: rem(30px);
          }
          padding-bottom: rem(45px);
        }
      }
      .right-wrapper {
        padding-left: rem(50px);
        .first-words {
          color: $white;
          font-weight: bold;
          font-size: rem(25px);
          margin-bottom: rem(24px);
        }
        @include media-breakpoint-down(md) {
          padding-left: rem(10px);
          .first-words {
            font-size: rem(20px);
            margin-bottom: rem(10px);
          }
        }
      }
    }
    //솔업은 이런회사 섹션
    &.is-solup {
      .row {
        height: 80vh
      }
      .left-wrapper {
        img {
          width: 80%;
        }
      }
      .first-letter {
        @include rem(font-size, 20px);
      }
      @include media-breakpoint-down(md) {
        .container.h-75 {
          height: unset !important;
        }
        .left-wrapper {
          display: none;
        }
        .right-wrapper {
          .h4 {
            font-size: rem(16px);
          }
        }
      }
    }
    &.place  {
      .row {
        height: 80vh;
      }
      .map-link {
        display: none;
      }
      @include media-breakpoint-down(md) {

        .wrap_map {
          height: unset;
          .map {
            height: rem(330px);
          }
        }
        .wrap_controllers {
          display: none;
        }
      }
    }
  }
}