header {
  background-color: $site-skyblue-color;
  > .navbar {
    padding-top: rem(15px);
    .navbar-brand {
      width: rem(300px);
      @include media-breakpoint-down(md) {
        width: rem(200px);
      }
      > img {
        width: 100%;
      }
    }
  }
  .navbar-dark .navbar-nav .nav-link {
    color: rgb(255, 255, 255);
  }
  .navbar-nav {
    li:not(:last-child) {
      @include rem(margin-right, 170px)
    }
  }
  .nav-item {

    .nav-link {
      color: $white;
      @include hover-focus-active {
        color: $white;
        text-decoration: none;
      }
      .second {
        font-size: rem(30px);
        @include media-breakpoint-down(md) {
          font-size: rem(20px);
        }
      }
      &.active {
        .red {
          color: $site-red-color;
        }
        .blue {
          color: $site-blue-color;
        }
      }
    }
    font: {
      family : $font-header-nav-item;
      weight: bold;
      size: rem(20px);
    }
    @include media-breakpoint-down(md) {
      font-size: rem(16px);
    }
  }
}
